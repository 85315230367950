export default function initErrorLogging() {
    if (process.env.NODE_ENV !== 'production') {
        return;
    }

    if (!process.env.SENTRY_DSN || process.env.SENTRY_DSN === 'false') {
        console.warn('Sentry is not (correctly) configured'); // eslint-disable-line no-console
        return;
    }

    const captureError = async error => {
        const { logError } = await import(/* webpackChunkName: "sentry" */ './sentry');
        console.log(logError)
        logError(error);
    }
    window.onerror = (message, url, line, column, error) => captureError(error);
    window.onunhandledrejection = event => captureError(event.reason);
}