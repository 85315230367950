/**
 * Utility for selecting elements on the page and assigning behaviour to those elements.
 * Example:
 *
 * function modalCreator(element) {
 *  // return public api to for example show or hide a modal
 *  // each public api is automatically augmented with addEventListener and removeEventListener (bound to the element)
 *  return {
 *      show: () => {},
 *  }
 * }
 *
 * const modalControllers = createControllerMap(document, '[data-modal]', modalCreator)
 *
 * // Note: Each element should have an unique id!
 * modalControllers['someModalId'].show();
 *
 * @param {*} container
 * @param {*} selector a css selector
 * @param {*} factory a factory function which will be executed for each element and should return a public api
 */
export function createControllerMap(container, selector, factory) {
    const controllers = createControllerList(container, selector, factory);

    return controllers.reduce((acc, controller) => {
        return {
            ...acc,
            [controller.id]: controller,
        }
    }, {});
}

/**
 * Same as createControllerMap only returns a array instead of an object (so the elements don't need to have an id)
 * @param {*} container
 * @param {*} selector
 * @param {*} factory
 */
export function createControllerList(container, selector, factory) {
    let elements = [container];

    if(typeof selector === 'function') {
        factory = selector;
    } else {
        elements = [...container.querySelectorAll(selector)];
    }

    return elements.map((element) => {
        const eventBus = {
            addEventListener: element.addEventListener.bind(element),
            removeEventListener: element.removeEventListener.bind(element),
        };

        return {
            id: element.id,
            ...factory(element),
            ...eventBus,
        }
    });
}
