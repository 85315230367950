const isWebApp = process.env.APP_ENV === 'web';
const isIframed = window.location !== window.parent.location;
const hasNativeDialogSupport = typeof window.HTMLDialogElement !== 'undefined' && typeof window.HTMLDialogElement.prototype.show === 'function';
const modalAudio = new Audio('/audios/tadah_06.mp3');

let dialogPolyfill = Promise.resolve();
if(!hasNativeDialogSupport) {
    dialogPolyfill = import(/* webpackChunkName: "dialog-polyfill" */'dialog-polyfill');
} else {
    dialogPolyfill = Promise.resolve({
        default: {
            registerDialog: () => {}
        },
    });
}

export default function modalCreator(element) {
    const closeButton = element.querySelector('[data-id="close"]');
    const hideCloseButton = isWebApp && isIframed && closeButton.dataset.backToHome === 'true';

    if (!hasNativeDialogSupport) {
        document.body.appendChild(element);
    }

    if (hideCloseButton) {
        closeButton.hidden = true;
    }

    dialogPolyfill.then(polyfill => {
        polyfill.default.registerDialog(element);
    });

    closeButton.addEventListener('click', close);
    element.addEventListener('close', () => handleClose());
    element.addEventListener('cancel', (e) => handleCancel(e));

    /**
     * Restores scroll position when user closes modal
     */
    const scrollPosition = (function () {
        let oldScrollTop;
        return {
            remember() {
                oldScrollTop = document.documentElement.scrollTop;
            },
            restore() {
                window.requestAnimationFrame(() => {
                    document.documentElement.scrollTop = oldScrollTop;
                    oldScrollTop = undefined;
                });
            }
        }
    })();

    function close(returnValue) {
        if(element.open && !hideCloseButton) {
            return element.close(returnValue);
        }
    }

    function handleClose() {
        if (closeButton.dataset.backToHome === 'true') {
            window.location.href = '/';
        } else {
            delete document.body.dataset.isModalOpen;
            scrollPosition.restore();
        }
    }

    function handleCancel(e) {
        if (hideCloseButton) {
            e.preventDefault();
        }
    }

    return {
        getReturnValue() {
            return element.returnValue
        },
        show(playAudio = true) {
            playAudio && modalAudio.play();
            scrollPosition.remember();

            dialogPolyfill.then(() => {
                document.body.dataset.isModalOpen = true;
                if (!element.open) {
                    element.showModal();
                }
            });
        },
        close,
        get open() {
            return !!element.open;
        }
    };
}
