export default function installModalClickEvents(modals) {
    function getModalToOpen(e) {
        let el = e.target;
        while(el) {
            if(el.dataset && el.dataset.openModal) {
                return el.dataset.openModal;
            }
            el = el.parentNode;
        }
        return null;
    }

    document.body.addEventListener('click', (e) => {
        const openModal = getModalToOpen(e);
        if (openModal) {
            modals[openModal].show();
        }
    });
}
